import React from "react";
import "./DutyAdvise.css";
import Footer from "../../../components/footer/Footer";
import HorizontalPictureTextBox from "../../../components/services/horizontalPictureTextBox/HorizontalPictureTextBox";
import imgFullDamaged from "../../../media/services/haftpflichtschaden_totalschaden.jpg";
import imgDamageEstimation from "../../../media/services/haftpflichtschaden_porsche_bild_in_bild.jpg";
import imgValue from "../../../media/services/haftpflichtschaden_restwert.jpg";
import imgTransportDamage from "../../../media/services/haftpflichtschaden_mustang.jpg";
import imgEvidence from "../../../media/introduction/beweissicherungs-guthaben.png";
import imgValueCalculation from "../../../media/introduction/wertermittlungs-gutachten.png";
import imgRepairCalculation from "../../../media/introduction/reparaturkalkulationen.png";

function DutyAdvise() {
  return (
    <div>
      <div className="as-container">
        <div className="as-content">
          <div className="as-content-heading">Haftpflicht-Gutachten</div>
          <div>
            unverschuldeter Unfall - Haftpflichtschaden Sie haben das Recht
            einen unabhängigen Sachverständigen zur Beweissicherung und zur
            Feststellung von Schadensumfang, Schadenshöhe, Wertminderung,
            Restwert, Wiederbeschaffungswert und voraussichtlicher
            Reparaturdauer zu beauftragen. Sie können frei in Ihrer Entscheidung
            sich den Sachverständigen Ihres Vertrauens aussuchen, es zahlt bei
            einem Haftpflichtfall grundsätzlich die Versicherung des Schädigers.
            Ausnahmen bilden sogenannte Bagatellschäden bis 750,00 €. Sie haben
            die freie Wahl der Reparaturwerkstatt, die Versicherung darf Ihnen
            nicht vorschreiben wo Sie reparieren lassen sollen. Wenn Sie
            regelmäßig Ihren Service in einer Werkstatt durchführen lassen und
            mit dieser zufrieden sind, wenden Sie sich an diese. Das Gutachten
            sichert Ihre Schadensersatzansprüche und ist Grundlage für eine
            qualitätsgerechte Beseitigung des entstandenen Schaden. Sollte es
            dennoch zu Problemen mit der Bezahlung des Honorar durch die
            Versicherung kommen, werden die Forderungen direkt durch mich
            gegenüber der Versicherung vertreten. Rufen Sie mich sofort nach
            einem Unfall an, auf jeden Fall vor Kontaktaufnahme mit der
            Versicherung des Schädigers. Ich erstelle Ihnen ein unabhängiges
            Schadengutachten zu Ihrem Haftpflichtschaden, auf dessen Basis die
            Versicherung den Schaden reguliert.
          </div>
          <div className="as-container">
            <div className="as-content">
              <div className="as-content-heading">Schadenfeststellung</div>
              <div className="as-picture-box-container">
                <HorizontalPictureTextBox
                  imgName={imgDamageEstimation}
                  paragraphs={[
                    "Fahrzeugbegutachtung zerlegt oder unzerlegt zur Feststellung der vorhandenen Schäden. ",
                    "Wichtig ist auch die Erfassung von kleinen Schäden wie z.B. Dellen, die erst durch einen Dellenspiegel sichtbar dokumentiert werden",
                  ]}
                ></HorizontalPictureTextBox>
              </div>
            </div>
            <div className="as-container">
              <div className="as-content">
                <div className="as-content-heading">Totalschaden</div>
                <div className="as-picture-box-container">
                  <HorizontalPictureTextBox
                    imgName={imgFullDamaged}
                    paragraphs={[
                      "Die Bestimmung des Wiederbeschaffungswertes zum Zeitpunkt des Unfalles ist gerade im Totalschadenfall von enormer Bedeutung",
                      "Hierzu ist die genauste Erfassung der vorhandenen Vor- und Altschäden notwendig",
                    ]}
                  ></HorizontalPictureTextBox>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="as-container-blue">
          <div className="as-content as-blue">
            <div className="as-container">
              <div className="as-content">
                <div className="as-blue-heading">Restwert</div>
                <div className="as-picture-box-container">
                  <HorizontalPictureTextBox
                    imgName={imgValue}
                    paragraphs={[
                      "Die Ermittlung des regionalen Restwertes ist gerade bei der fiktiven Abrechnung von Bedeutung.",
                      "Hierbei arbeite ich mit regionalen Aufkäufern und Autoverwertern zusammen",
                    ]}
                  ></HorizontalPictureTextBox>
                </div>

                <div className="as-blue-heading">
                  Transportschadensbegutachtung
                </div>
                <div className="as-picture-box-container">
                  <HorizontalPictureTextBox
                    imgName={imgTransportDamage}
                    paragraphs={[
                      "Sie überführen ein Fahrzeug von A nach B und stellen hierbei einen Transportschaden fest, ich erstelle Ihnen ein Gutachten zur Feststellung der Schäden",
                      "z.B. Fahrzeugüberführung aus den USA nach Deutschland und schlussendlich zum Fahrzeughalter mit festgestellten Lackschäden an der Karosserie",
                    ]}
                  ></HorizontalPictureTextBox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="as-container">
        <div className="as-content">
          <div className="as-content-heading">Weitere Gutachten</div>
          <div className="as-container">
            <div className="as-content">
              <div className="as-content-heading">
                Beweissicherungsgutachten
              </div>
              <div className="as-picture-box-container">
                <HorizontalPictureTextBox
                  imgName={imgEvidence}
                  paragraphs={[
                    "Beweissicherungsgutachten werden benötigt um Rechtsansprüche geltend zu machen. Beispielsweise bei verschwiegenden Mängeln beim Verkauf des Fahrzeuges, Aufdecken von Manipulationen, wie z.B. beim Tachostand.",
                    "Das Beweissicherungsgutachten dient dem Rechtsanwalt zur Durchsetzung der Forderungen des Klägers. Durch die Aufnahme des Schades wird auch die Beweissicherung im Rahmen eines Schadensgutachtens durchgeführt.",
                  ]}
                ></HorizontalPictureTextBox>
              </div>
            </div>
            <div className="as-container">
              <div className="as-content">
                <div className="as-content-heading">Wertermittlungsgutachten</div>
                <div className="as-picture-box-container">
                  <HorizontalPictureTextBox
                    imgName={imgValueCalculation}
                    paragraphs={[
                      "Bei Wertermittlungsgutachten wird der Wert des Fahrzeugs am Markt, auch am regionalen Markt, ermittelt.",
                      "Es werden alle wertsteigernden und eventuellen Mängel beurteilt und der Einkaufs-, Verkaufs- oder Wiederbeschaffungswert bestimmt."
                    ]}
                  ></HorizontalPictureTextBox>
                </div>
              </div>
            </div>
            <div className="as-container">
              <div className="as-content">
                <div className="as-content-heading">Reparaturkalkulationen</div>
                <div className="as-picture-box-container">
                  <HorizontalPictureTextBox
                    imgName={imgRepairCalculation}
                    paragraphs={[
                      "Sie haben einen Bagatellschaden und sind sich nicht sicher, wie dieser zu bewerten ist?",
                      "Bei Schäden unter 1000,00€ erstelle ich Ihnen eine Reparaturkalkulation, die im Haftpflichtschadenfall von der Versicherung bezahlt wird."
                    ]}
                  ></HorizontalPictureTextBox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}

export default DutyAdvise;
