import React, { useState } from "react";
import "./NavbarMobile.css";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ContactButton } from "../buttons/ContactButton";
import { Modal } from "../../components/modal/Modal";
function NavbarMobile() {
  const [click, setClick] = useState(false);

  
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const [showModal, setShowModal] = useState(false);
  function hideModal(){
    console.log("clicked close modal");
    setShowModal(false);
  };
  return (
    <>
      {showModal && (
        <Modal handleClose={() => hideModal()}>
        </Modal>
      )}
      <Link to="/" className="navbar-text" onClick={closeMobileMenu}>
        <b>KFZ - Sachverständigenbüro Andreas Kuster</b>
      </Link>
      <div className="menu-icon" onClick={handleClick}>
        <i className="fas fa-bars" />
      </div>
      <ul className={click ? "nav-menu active" : "nav-menu"}>
        <li className="nav-item">
          <Link to="/" className="nav-links" onClick={closeMobileMenu}>
            Home
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/Process" className="nav-links" onClick={closeMobileMenu}>
            Unfall? Was tun?
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/Services/DutyAdvise" className="nav-links" onClick={closeMobileMenu}>
            Arten von Gutachten
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/Services/TravelMobile" className="nav-links" onClick={closeMobileMenu}>
            Reisemobile und Wohnanhänger
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/Services/AdditionalServices" className="nav-links" onClick={closeMobileMenu}>
            Weitere Leistungen
          </Link>
        </li>
       
        <li className="nav-item">
          <Link to="/AboutMe" className="nav-links" onClick={closeMobileMenu}>
            Über mich
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/Impressum" className="nav-links" onClick={closeMobileMenu}>
            Impressum
          </Link>
        </li>
        <ContactButton
          buttonStyle="btn--outline"
          onClick={() => {
            closeMobileMenu();
            setShowModal(true);
          }}
        >
          Kontakt
        </ContactButton>
      </ul>
    </>
  );
}

export default NavbarMobile;
