import React from "react";
import "./AboutMeBio.css";
import imgAboutMe from "../../../media/aboutme/aboutme.png";

function AboutMeBio() {
  return (
    <div className="about-me-bio-container">
      <div className="about-me-bio-content">
        <div className="about-me-bio-title">Andreas Kuster</div>
        <div >
          Dipl.-Ing. (FH) / Kfz-Techniker-Meister
        </div>
      </div>
      <div className="about-me-bio-image">
        <img width="50%" src={imgAboutMe} alt="Missing"></img>
      </div>
      <div className="about-me-bio-content">
        <div className="about-me-bio-title about-me-bio-underline">Sachverstand seit 1982!</div>
        <div className="about-me-bio-text">
          Ich bin seit über 30 Jahren im Bereich der Kfz-Instandsetzung tätig.</div>
          <div className="about-me-bio-text">
          1982 erwarb ich den Ingenieurabschluss Fachrichtung Kfz-Technik an der
          Offiziershochschule in Löbau in der DDR.</div>
          <div className="about-me-bio-text">
          Dieser wurde durch das
          Ministerium für Wissenschaft und Kunst des Freistaat Sachsen
          gleichwertig gestellt mit dem akademischen Grad Dipl.-Ing. (FH) in der
          BRD.
          </div>
          <div className="about-me-bio-text">
          2006 wurde mir nach erfolgreich bestandener Prüfung von der
          Handwerkskammer Freiburg der Kfz-Techniker-Meister Titel verliehen.
          </div>
          <div className="about-me-bio-text">
          Durch zahlreiche Weiterbildungsmaßnahmen im Kfz technischen Bereich,
          wird ein aktuell technisch hohes Niveau gewährleistet.
          </div>
          <div className="about-me-bio-text">
          Ich besitze
          zusätzlich die Qualifikation als Fachkraft für Hochvoltsysteme in
          Kraftfahrzeugen.
          </div>
          <div className="about-me-bio-text">Für die Schadenkalkulation arbeite ich mit der
          Software von Audatex, entsprechende Qualifizierungslehrgänge wurden
          dafür besucht.</div>
          <div className="about-me-bio-text">
            2017/2018 Weiterbildung zum Sachverständigen für
          Reisemobile und Wohnanhänger geprüft durch den BVSK.
          </div>
        
      </div>
    </div>
  );
}

export default AboutMeBio;
