import React, { useEffect, useState } from "react";
import "./Navbar.css";
import NavbarDesktop from "./NavbarDesktop";
import NavbarMobile from "./NavbarMobile";

function Navbar() {
  const [navbarMobile, setNavbarMobile] = useState(false);
  const showMobile = () => {
    if (window.innerWidth <= 960) {
      setNavbarMobile(true)
    } else {
      setNavbarMobile(false)
    }
  };

  useEffect(() => {
    showMobile();
  }, []);

  window.addEventListener("resize", showMobile);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          {!navbarMobile && <NavbarDesktop/>}
          {navbarMobile && <NavbarMobile/>}
        </div>
      </nav>
    </>
  );
}
export default Navbar;
