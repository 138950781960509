import './Modal.css';

 export const Modal = ({ handleClose}) => {
  const showHideClassName ="modal display-block";

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
      <div>
          <p>
            Telefon:{" "}
            <a className="link" href="tel:0763/1749406">
              07631/749406
            </a>
          </p>
          <p>
            Mobil:{" "}
            <a className="link" href="tel:0152/54298892">
              0152/54298892
            </a>
          </p>
          <p>
            Fax:{" "}
            <a className="link" href="tel:07631/749456">
              07631/749456
            </a>
          </p>
          <p>
            E Mail:{" "}
            <a className="link" href="mailto:info@kfz sv kuster.de">
              info@kfz sv kuster.de
            </a>
          </p>
          <p>
            Internet:{" "}
            <a className="link" href="www.kf-sv-kuster.de">
              www.kfz-sv-kuster.de
            </a>
          </p>
        </div>
        <button type="button" className="modal-button" onClick={()=> {
            console.log("closed modal clicked within modal");
            handleClose()}}>
                <p>Schließen</p>
        </button>
      </section>
    </div>
  );
};