import React from 'react'
import AboutMeBio from '../../components/aboutMe/aboutMeBio/AboutMeBio';
import AboutMeContact from '../../components/aboutMe/aboutMeContact/AboutMeContact';
import AboutMeSponsoring from '../../components/aboutMe/aboutMeSponsoring/AboutMeSponsoring';
import Footer from '../../components/footer/Footer';
function AboutMe() {
    return (
        <div className="aboutMe-container">
            <AboutMeBio/>
            <AboutMeContact/>
            <AboutMeSponsoring/>
            <Footer/>
        </div>
    )
}
export default AboutMe
