import React from "react";
import "./ProcessContentQuestion.css";

function ProcessContentQuestion() {
  return (
    <div className="process-content-container">
      <div className="process-content-headline">
        SIE WURDEN DURCH EINE ANDERE PERSON ODER EIN FAHRZEUG GESCHÄDIGT?
      </div>
      <ul className="process-content-head-container">
        <li className="process-content-head-text li">
          Es liegt ein Haftpflichtschaden über 750,00 EUR vor
        </li>
        <li className="process-content-head-text li">
          Ihre Rechte sind durch den §249 BGB geregelt
        </li>
      </ul>
    </div>
  );
}

export default ProcessContentQuestion;
