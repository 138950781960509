import './App.css';
import Navbar from './components/navbar/Navbar';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import AboutMe from './pages/AboutMe/AboutMe';
import Home from './pages/Home/Home';
import Process from './pages/process/Process';
import Impressum from './pages/impressum/Impressum';
import DutyAdvise from './pages/services/dutyAdvise/DutyAdvise';
import AdditionalServices from './pages/services/AdditionalServices/AdditionalServices';
import ScrollToTop from './components/ScrollToTop';
import TravelMobile from './pages/services/travelmobile/TravelMobile';

function App() {
  return (
      <>
      <div className="app-overall">
      <Router>    
      <ScrollToTop/>      
      <Navbar/>
      <Switch>
          <Route path="/" exact component={Home}/>
          <Route path="/Home" exact component={Home}/>
          <Route path="/AboutMe" component={AboutMe}/>
          <Route path="/Process" component={Process}/>
          <Route path="/Impressum" component={Impressum}/>
          <Route path="/Services/DutyAdvise" component={DutyAdvise}></Route>
          <Route path="/Services/AdditionalServices" component={AdditionalServices}></Route>
          <Route path="/Services/TravelMobile" component={TravelMobile}></Route>
        </Switch>
      </Router>   
      
      </div> 
      </>
  );
}
export default App;
