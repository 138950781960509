import React from "react";
import "./ProcessWay.css";
import imgProcessAdvisor from "../../../../media/process/process-advisor.jpg";
import ProcessStep from "./ProcessStep";

function ProcessWay() {
  return (
    <div className="process-way-container">
      
      <div className="process-way-heading">
        <div className="process-way-title">Der Weg zum KFZ Gutachten:</div>
        <div className="process-way-image">
          <img width="80%" src={imgProcessAdvisor} alt="Missing"></img>
        </div>        
      </div>
      
      <div>
        <div className="process-way-steps-container">
          <ProcessStep
            number="1"
            heading="TERMIN VEREINBAREN"
            content="Der erste Schritt zum KFZ-Gutachten ist die
Kontaktaufnahme mit einem Gutachter Ihres
Vertrauens. Dazu können Sie mich einfach anrufen
oder mir eine E-Mail zusenden."
          />
          
          <ProcessStep
            number="2"
            heading="BEGUTACHTUNG DES SCHADENS"
            content="Nachdem Sie einen Termin vereinbart haben, wird das Fahrzeug
begutachtet. Neben der Dokumentation der Fahrzeugdaten, der
Ausstattung und des Fahrzeugzustandes wird umfangreiches
Lichtbildmaterial erstellt. Außer der Fahrzeugpapiere müssen Sie
zum vereinbarten Termin keine weiteren Dokumente mitbringen.
Die Schadensaufnahme vor Ort dauert meist nur 30 Minuten."
          />
          <ProcessStep
            number="3"
            heading="BEWERTUNG"
            content="Des weiteren wird der Wiederbeschaffungswert, die voraussichtlichen
Instandsetzungskosten, der Restwert, die merkantile Wertminderung,
Wertverbesserung, die Reparaturzeit, die Wiederbeschaffungszeit sowie
Nebenkosten wie zum Beispiel Radioumbaukosten ermittelt."
          />
          <ProcessStep
            number="4"
            heading="ERSTELLEN DES KFZ-GUTACHTENS"
            content="Erstellung des KFZ-Gutachtensaufgrund der Fahrzeugdokumentationen.
Dies kann bis zu drei Tage dauern. In dringenden Fällen können
Gutachten auch innerhalb von 24 h erstellt werden."
          />
          <ProcessStep
            number="5"
            heading="SIE ERHALTEN DAS GUTACHTEN"
            content="Nach Fertigstellung erhalten Sie das Gutachten, je nach Vereinbarung
postalisch, per Mail oder persönlich."
          />
          <ProcessStep
            number="6"
            heading="ABRECHNUNG DER KOSTEN"
            content="Die Kosten des Gutachtenshängen vom ermittelten Fahrzeugwert ab. Bei
einem Unfallgutachten können Sie auf Wunsch mit einer Abtretungserklärung
die Kosten an uns abtreten. Die Kosten werden direkt mit der gegnerischen
Versicherung abgerechnet."
          />
        </div>
      </div>
    </div>
  );
}

export default ProcessWay;
