import React from "react";
import "./IntroductionText.css";

function IntroductionText() {
  return (
    <div className="introduction-text-container">
      <div className="introduction-text-heading">
        <b>Seit über 10 Jahren Ihr unabhängiger KFZ-Sachverständiger im Markgräflerland!</b>
      </div>
      <div className="introduction-text">
        <br />
        Erstellung von Kfz Schadengutachten und Beweissicherungsgutachten. Seid
        über 10 Jahren ihr kompetenter und zuverlässiger Sachverständiger in der
        Region Markgräflerland. Zusätzlich tätig als Sachverständiger für
        Reisemobile und Caravan, unabhängig und neutral gegenüber den
        Versicherungen.
        <br />
        <br/>
        Setzen Sie sich als erstes mit dem Kfz-Sachverständigen Ihres Vertrauens
        in Verbindung und beginnen mit Ihm eine unabhängige freie
        Schadensregulierung. Die Beweissicherung durch den Sachverständigen
        hilft bei Unstimmigkeiten der Schuldfragen, sowie der Feststellung des
        Schadenumfang und Plausibilität.
        <br />
        <br /> Egal ob am Unfallort, bei Ihnen daheim oder auch in der Werkstatt
        – ich komme im Markgräflerland und Umgebung direkt zu Ihnen. Bei einem
        in Deutschland verursachten Unfall / Schaden, auch Länderübergreifend
        nach Frankreich oder in die Schweiz.
        <br />
        <br /> Die Dienstleistungen umfassen verschiedene Fachbereiche und wird
        Ihnen helfen all Ihre Schadensansprüche geltend zu machen. Gehen Sie
        also nicht arglos mit der Regulierung eines Schadens um sondern lassen
        Sie mich als unabhängigen KFZ-Gutachter für Sie tätig werden.
        <br />
        <br />
      </div>
    </div>
  );
}

export default IntroductionText;
