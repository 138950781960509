import React from "react";
import "./ProcessStep.css";

function ProcessStep({number, heading, content}) {
  return (
    <div className="process-way-step-container">
      <div className="process-way-step-heading">
        <div className="process-way-step-heading-number">
          <div className="circle">
            <p>{number}</p>
          </div>
        </div>
        <div className="process-way-step-heading-title">{heading}</div>
      </div>
      <div className="process-way-step-content">
        <span className="process-way-step-content-line-left"></span>
        <span className="process-way-step-content-line-right"></span>
        <div className="process-way-step-content-text">
          {content}
        </div>
      </div>
    </div>
  );
}

export default ProcessStep;
