import React from "react";
import "./AboutMeContact.css";
function AboutMeContact() {
  return (
    <div className="about-me-contact-container">
      <div className="about-me-contact-heading">Kontaktieren Sie mich</div>
      <div className="about-me-contact-text">
        Sie benötigen einen Kfz-Sachverständigen zur Erstellung eines
        Schadengutachtens oder Wertgutachtens oder haben Fragen zur
        Vorgehensweise im Falle eines Verkehrsunfalls? Kontaktieren Sie mich
        unverbindlich.
      </div>
      <div className="about-me-contact-text-info">
        <p>Telefon: <a className="link" href="tel:07631/749406">07631/749406</a></p>
        <p>Mobil: <a className="link" href="tel:0152/54298892">0152/54298892</a></p>
        <p>Mail: <a className="link" href="mailto:info@kfz-sv-kuster.de">info@kfz-sv-kuster.de</a></p>
      </div>
    </div>
  );
}

export default AboutMeContact;
