import React from 'react'
import ProcessHead from '../../components/process/processHead/ProcessHead'
import ProcessContentQuestion from '../../components/process/processContent/ProcessContentQuestion'
import Footer from '../../components/footer/Footer';
import "./Process.css"
import ProcessContentLaw from '../../components/process/processContent/ProcessContentLaw';
import ProcessContentRights from '../../components/process/processContent/ProcessContentRights';
import ProcessWay from '../../components/process/processContent/processWay/ProcessWay';

function Process() {
    return (
        <div className="process-container">
            <ProcessHead/>
            <ProcessContentQuestion/>
            <ProcessContentLaw/>
            <ProcessContentRights/>
            <ProcessWay/>
            <Footer/>
        </div>
    )
}

export default Process
