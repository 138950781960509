import React from "react";
import "./ProcessContentRights.css";
import Right from "../../rights/Right";
function ProcessContentRights() {
  var firstParagraph = [
    "Als Geschädigter nach einem Unfall mit Ihrem Auto haben Sie das Recht Ihr Fahrzeug durch einen unabhängigen Kfz-Sachverständigen, dem Sie vertrauen, begutachten zu lassen und mit dem dabei entstandenen Schaden zu beauftragen. Die gegnerische Versicherung muss die Kosten füride Erstellung des Gutachtes tragen, da diese separate Schadensersatzansprüche darstellen. Der Geschädigte muss hierbei nicht befürchten in Vorkasse treten zu müssen.",
  ];
  firstParagraph.push(
    "Oftmals versucht der Leistungserbringer Sie von der Erstellung eines unabhängigen Gutachtens abzubringen, da die Sachlage offensichtlich ist oder ein einfacher Kostenvoranschlag ausreichend wäre. Lassen Sie sich hierbei nicht beeindrucken und fordern Sie Ihren eigenen Kfz-Sachverständigen an."
  );
  firstParagraph.push(
    "Insbesondere dann, wenn die Schuldfrage im Nachhinein plötzlich nicht mehr klar ist oder Schadensersatzansprüche diskutiert werden, dient ein durch einen Sachverständigen"+
    "erstelltes Gutachten nicht nur zur Ermittlung der Reparaturkosten (einzige Position, die ein Kostenvoranschlag KV abdeckt), "+
    "der Wertminderung, des Wiederbeschaffungswertes, des Restwertes, der Reparatur- und Wiederbeschaffungsdauer, des Nutzungsausfalles und der Beurteilung der Verkehrs- und Betriebssicherheit, "+
    "sondern nutzt vor allem der Beweissicherung."
  );
  firstParagraph.push(
    "Mit einem Kfz-Gutachten haben Sie alle Möglichkeiten Ihre Ansprüche durchzusetzen, ggf. auch juristisch."
  );
  var secondParagraph = [
    "Das erstellte Gutachten unterstützt Sie auch, wenn Sie Ihre Rechtsansprüche mit einem Rechtsanwalt abwickeln lassen wollen. Auch diese Kosten werden Ihnen von der gegnerischen Versicherung ersattet."
  ];
  var thirdParagraph = [
    "\"Die Fiktive Abrechnung\" - Sie haben das Recht auf eine Reparatur in der Werkstatt zu verzichten und die Reparaturkosten bzw. den entstandenen Schaden von der Versicherung des Unfallgegners in Form einer direkten Auszahlung einzufordern (bei fiktiver Abrechnung ohne Rechnung wird in der Regel nur der Nettobetrag erstattet).",
  ];
  thirdParagraph.push(
    "Bei fiktiven Abrechnungen werden oftmals weitere Kürzungen durch die leistungserbringende Versicherung vorgenommen. So werden insbesondere fiktive Positionen wie Verbringungskosten, Ersatzteilaufschläge oder aber auch die im Gutachten berücksichtigten Stundenverrechnungssätze gekürzt. Inwieweit diese Kürzungen gerechtfertigt sind, unterliegt einer rechtlichen Prüfung."
  );
  var fourthParagraph = [
    "Durch die Gutachtenerstellung kann eine Vielzahl von Schadensersatzpositionen und Schäden festgestellt werden, die erstattet werden müssen.",
  ];

  return (
    <div className="process-content-rights-container">
      <div className="process-content-rights-heading">
        Das sind Ihre Rechte nach einem unverschuldeten Autounfall:
      </div>
      <div>
        <Right
          heading="1. Sachverständiger Ihres Vertrauens:"
          paragraph={firstParagraph}
        />
        <Right
          heading="2. Rechtsanwalt Ihres Vertrauens:"
          paragraph={secondParagraph}
        />
        <Right
          heading="3. Fiktive Abrechnung (Auszahlung des
            Schadenersatzbetrages)"
          paragraph={thirdParagraph}
        />
        <Right
          heading="4. Weitere Schadenersatzpositionen"
          paragraph={fourthParagraph}
        />
      </div>
    </div>
  );
}

export default ProcessContentRights;
