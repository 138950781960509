import React, { useState } from "react";
import "./NavbarDesktop.css";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { ContactButton } from "../buttons/ContactButton";
import { Modal } from "../../components/modal/Modal";
function NavbarDesktop() {
  const [showModal, setShowModal] = useState(false);
  function hideModal() {
    console.log("clicked close modal");
    setShowModal(false);
  }
  return (
    <div className="nav-container">
      
      <Link to="/" className="navbar-text">
        <b>KFZ - Sachverständigenbüro <br/> Andreas Kuster</b>
      </Link>
      <Link to="/" className="nav-links nav-item">
        Home
      </Link>
      <Link to="/Process" className="nav-links nav-item">
        Unfall? Was tun?
      </Link>
      {showModal && <Modal handleClose={() => hideModal()}></Modal>}
        <Link to="/Services/DutyAdvise" className="nav-links">
        Arten von Gutachten
      </Link>
      <Link to="/Services/TravelMobile" className="nav-links">
        Reisemobile und Wohnanhänger
      </Link>
      <Link to="/Services/AdditionalServices" className="nav-links">
        Weitere Leistungen
      </Link>
      
      <Link to="/AboutMe" className="nav-links">
        Über mich
      </Link>
      <Link to="/Impressum" className="nav-links">
        Impressum
      </Link>
      <ContactButton
        buttonStyle="btn--outline"
        onClick={() => {
          setShowModal(true);
        }}
      >
        Kontakt
      </ContactButton>
    </div>
  );
}

export default NavbarDesktop;
