import React from 'react'
import "./Right.css";

function Right({heading, paragraph}) {
    const div = paragraph.map((element) =>
    <div key={element} className="rights-content">
      {element}
    </div>
  );
    return (
        <div className="rights-container">
            <div className="rights-heading">
                {heading}
            </div>
            {div}            
        </div>
    )
}
export default Right
