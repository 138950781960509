import React from "react";
import "./IntroductionDamageCase.css";

function IntroductionDamageCase() {
  return (
    <div className="introduction-damage-case-outer-container">
      <div className="introduction-damage-case-inner-container">
        <div className="introduction-damage-case-text">
          <h2>Im Schadensfall erreichen Sie mich <br/>rund um die Uhr!</h2>
          <h3>
            <br/>Telefon: <a className="link" href="tel:07631/749406">07631 / 749406</a>
            <br/>Mobil: <a className="link" href="tel:0152/54298892">0152 / 54298892</a>
            <br/>Mail: <a className="link" href="mailto:info@kfz-sv-kuster.de">info@kfz-sv-kuster.de</a>
          </h3>
        </div>
      </div>
    </div>
  );
}

export default IntroductionDamageCase;
