import "./AdditionalServices.css";
import Footer from "../../../components/footer/Footer";
import HorizontalPictureTextBox from "../../../components/services/horizontalPictureTextBox/HorizontalPictureTextBox";
import imgCamera from "../../../media/services/feuchtesuche.png";
import imgCap from "../../../media/services/kapmessung.png";
import imgProbeAction from "../../../media/services/drohneaktion.png";
import imgMobileRoof from "../../../media/services/womodach.png";
import imgAccident from "../../../media/services/unfallsituation.png";
import imgRepair from "../../../media/services/instandsetzung.jpg";
import imgDocumentation from "../../../media/services/dokumentation.jpg";


function AdditionalServices() {
  return (
  <div>
    <div className="as-container">
      <div className="as-content">
        <div className="as-content-heading">Weitere Leistungen</div>
        <div className="as-content">
        <div className="as-content-heading"> Feuchtesuche in Campingfahrzeugen</div>
        <div className="as-picture-box-container">
          <HorizontalPictureTextBox
            heading="Feuchtelokalisierung mit Wärmebildkamera"
            imgName={imgCamera}
            paragraphs={[
              "Feuchtigkeitsmessgerät FLIR MR 160",
              "Darstellung der Feuchte auf dem Display der Wärmebildkamera (hier mit einem Stück feuchtem Holz). Deutlich die feuchte Stelle erkennbar.",
            ]}
          ></HorizontalPictureTextBox>
          <HorizontalPictureTextBox
            heading="Kapazitive Messung und Messung
            nach dem Leitfähigkeitprinzip möglich"
            imgName={imgCap}
            paragraphs={[
              "Zerstörungsfreie Messung (kapazitives Messverfahren)",
              "Hier bei einer Messung am Fenster dargestellt.",
            ]}
          ></HorizontalPictureTextBox>
          </div>
        </div>
      </div>
      </div>
      <div className="as-container-blue">
        <div className="as-content">
      <div className="as-content as-blue">
        <div className="as-blue-heading">DROHNENNUTZUNG ZUR BEGUTACHTUNG</div>
        <div className="as-picture-box-container">
        <HorizontalPictureTextBox
            heading="Drohne im Einsatz"
            imgName={imgProbeAction}
            paragraphs={[
              "Schneller unkomplizierter Einsatz.",
              "Hier kommt die Drohne DJI Mavic Air 2 zum Einsatz. Videos in 4K Qualität und Fotos in 4MP Auflösung können so verarbeitet werden.",
            ]}
          ></HorizontalPictureTextBox>
          <HorizontalPictureTextBox
            heading="Fotos aus allen Perspektiven"
            imgName={imgMobileRoof}
            paragraphs={[
              "Fotos nutzbar um Schäden am Dach und Aufbauten zu erkennen. Dokumentation der auf dem Dach befindlichen Aufbauten.",
              "Hier zum Beispiel vom Wohnmobildach."
            ]}
          ></HorizontalPictureTextBox>
          <HorizontalPictureTextBox
            heading="Unfallsituation"
            imgName={imgAccident}
            paragraphs={[
              "Einschätzung und Plausibilitätsprüfung an Hand konkreter der örtlicher Situation entsprechenden und den Unfall beeinflussenden Begebenheiten."
            ]}
          ></HorizontalPictureTextBox>
        </div>
        </div>
    </div>
    </div>
    <div className="as-container">
        <div className="as-content">
        <div className="as-content">
        <div className="as-content-heading"> Reparaturbegleitung</div>
        <div className="as-picture-box-container">
          <HorizontalPictureTextBox
            heading="Dokumentation der Instandsetzung"
            imgName={imgRepair}
            paragraphs={[
              "Fotografische Erfassung der Schadensaufnahme über die einzelnen Reparaturschritte bis zum Abschluss der Reparaturmaßnahmen",
            ]}
          ></HorizontalPictureTextBox>
          <HorizontalPictureTextBox
            heading="Überprüfung der fachlich korrekten Reparaturmaßnahmen"
            imgName={imgDocumentation}
            paragraphs={[
              "Kontrolle der Qualität, des Einsatzes von Originalersatzteilen und fachgerechten Instandsetzung",
              "Überprüfung der Reparatur, sodaß keine technische Wertminderung entsteht",
            ]}
          ></HorizontalPictureTextBox>
          </div>
          </div>
        </div>
      </div>
    <Footer></Footer>
    </div>
  );
}

export default AdditionalServices;
