import React, {useState, useEffect} from 'react'
import "./ProcessHead.css"
function ProcessHead() {
    const [fadeIn, setFadeIn] = useState(false);   
    useEffect (()=>{
            console.log(fadeIn)
            setFadeIn(true)
    },[fadeIn]);
    return (
        <div className="process-head-container">
            <div className={fadeIn? "text-container text-container-fade-in": "text-container"}>Unfall? Was tun?</div>
        </div>
    )
}

export default ProcessHead
