import React from "react";
import "./IntroductionCard.css";
import { Link } from 'react-router-dom';

const IntroductionCard = ({link, imageURI, textHeading, text }) => (
  <Link to={link} className="introduction-card-image">
    <img className="img" src={imageURI} alt="Missing"></img>
    <div className="introduction-card-text">
      <div>
          <br/>
        <h2>{textHeading}</h2>
        {text}
        <br/>
        <br/>
      </div>
    </div>
    <div></div>
  </Link>
);
export default IntroductionCard;
