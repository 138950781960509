import React from 'react'
import './Footer.css'

function Footer() {

    return (      
        <div className="footer">
            <div className="footer-container">
                <div className="footer-element">
                <h3  className="line-padding">Kontakt</h3>
                    <p>Andreas Kuster</p>
                    <p>(Dipl.-Ing. (FH)/Kfz-Techniker-Meister)</p>
                    <p>Unterm Dorf 5</p>
                    <p>79395 Neuenburg</p>
                </div>
                <div className="footer-element">
                    <h3  className="line-padding">24/7 erreichbar!</h3>
                    <p>Telefon: 07631/749406</p>
                    <p>Mobil: 0152/54298892</p>
                    <p>Mail: info@kfz-sv-kuster.de</p>
                </div>
                <div className="footer-element">
                    <h3 className="line-padding">Social Media</h3>
                    <p><i className="fab fa-twitter fa-lg"></i><a className="link" href="https://twitter.com/kuster1960">Kuster1960</a></p>
                    <p><i className="fab fa-facebook-square fa-lg"></i> <a className="link" href="https://www.facebook.com/Kfz.Sachverstaendigenbuero.A.Kuster/">Kfz SV Andreas Kuster</a></p>
                </div>
            </div>
            </div>  
    )
}

export default Footer
