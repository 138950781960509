import React from "react";
import "./ImpressumContainer.css";

function ImpressumContainer() {
  return (
    <div className="impressum-container">
      <div className="impressum-content-container">
        <div className="impressum-content-title">
          <h3>IMPRESSUM</h3>
        </div>
        <div className="impressum-content">
          <p>KFZ Sachverständigenbüro Andreas Kuster</p>
          <p>Unterm Dorf 5</p>
          <p>79395 Neuenburg</p>
        </div>
        <div className="impressum-content">
          <p>
            Telefon:{" "}
            <a className="link" href="tel:0763/1749406">
              07631/749406
            </a>
          </p>
          <p>
            Mobil:{" "}
            <a className="link" href="tel:0152/54298892">
              0152/54298892
            </a>
          </p>
          <p>
            Fax:{" "}
            <a className="link" href="tel:07631/749456">
              07631/749456
            </a>
          </p>
          <p>
            E Mail:{" "}
            <a className="link" href="mailto:info@kfz sv kuster.de">
              info@kfz sv kuster.de
            </a>
          </p>
          <p>
            Internet:{" "}
            <a className="link" href="www.kf-sv-kuster.de">
              www.kfz-sv-kuster.de
            </a>
          </p>
        </div>
        <div className="impressum-content">
          <p>Geschäftsinhaber und inhaltlich Verantwortlicher</p>
          <p>Andreas Kuster (Dipl. Ing. (FH)/Kfz Techniker Meister)</p>
        </div>
        <div className="impressum-content">
          <p>Steuer Nr.: 12135/15118</p>
          <p>UST Id. Nr.: DE291175932</p>
        </div>
        <div className="impressum-content-title">
          <h3>Haftungshinweis</h3>
        </div>
        <div className="impressum-content">
          <p>
            Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine
            Haftung für Inhalte externer Links.
          </p>
          <p>
            Für den Inhalt der verlinkten Seiten sind ausschließlich deren
            Betreiber verantwortlich.
          </p>
        </div>
        <div className="impressum-content-title">
          <h3>Quellenangabe für die verwendeten Bilder</h3>
        </div>
        <div className="impressum-content">
          <p>Soweit nicht anders ausgewiesen: Andreas Kuster</p>
          <p>Unfall? Was tun? - Titelbild <a className="link" href="https://de.freepik.com/fotos/auto">Auto Foto erstellt von aleksandarlittlewolf</a></p>
            <p>Der Weg zum Gutachten - Grafik <a className="link" href="http://www.freepik.com">Designed by rawpixel.com / Freepik</a></p>
        </div>
        <div className="impressum-content-title">
          <h3>Datenschutz</h3>
        </div>
        <div className="impressum-content">
          <p>
            Die Nutzung unserer Webseite ist in der Regel ohne Angabe
            personenbezogener Daten möglich Soweit auf unseren Seiten
            personenbezogene Daten (beispielsweise Name, Anschrift oder eMail
            Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf
            freiwilliger Basis Diese Daten werden ohne Ihre ausdrückliche
            Zustimmung nicht an Dritte weitergegeben
          </p>
          <p>
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z B
            bei der Kommunikation per E Mail) Sicherheitslücken aufweisen kann
            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
            nicht möglich
          </p>
          <p>
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
            Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
            angeforderter Werbung und Informationsmaterialien wird hiermit
            ausdrücklich widersprochen Die Betreiber der Seiten behalten sich
            ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung
            von Werbeinformationen, etwa durch Spam Mails, vor.
          </p>
        </div>
        <div className="impressum-content">
        </div>
        <div className="impressum-content-title">
        </div>
        <div className="impressum-content">Design by: <a className="link" href="https://www.linkedin.com/in/moritz-kross-744a1a164/">Moritz Kroß</a></div>
      </div>
    </div>
  );
}

export default ImpressumContainer;
