import React from "react";
import Footer from "../../components/footer/Footer";
import Introduction from "../../components/introduction/Introduction";
import IntroductionCardBox from "../../components/introduction/introductionCardBox/IntroductionCardBox";
import IntroductionText from "../../components/introduction/introductionContent/IntroductionText";
import IntroductionDamageCase from "../../components/introduction/introductionDamageCase/IntroductionDamageCase";

function Home() {
  return (
    <div className="home-container">
      <Introduction />
      <IntroductionText />
      <IntroductionDamageCase />
      <IntroductionCardBox />
      <Footer />
    </div>
  );
}
export default Home;
