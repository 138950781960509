import React from "react";
import "./ProcessContentLaw.css";

function ProcessContentLaw() {
  return (
    <div className="process-content-law-container">
      <div className="process-content-law-heading">
        § 249 ART UND UMFANG DES SCHADENSERSATZES
      </div>
      <div className="process-content-law-text">
        (1) Wer zum Schadensersatz verpflichtet ist, hat den Zustand
        herzustellen, der bestehen würde, wenn der zum Ersatz verpflichtende
        Umstand nicht eingetreten wäre.
      </div>
      <div className="process-content-law-text">
        (2) Ist wegen Verletzung einer Person oder wegen Beschädigung einer
        Sache Schadensersatz zu leisten, so kann der Gläubiger statt der
        Herstellung den dazu erforderlichen Geldbetrag verlangen. Bei der
        Beschädigung einer Sache schließt der nach Satz 1 erforderliche
        Geldbetrag die Umsatzsteuer nur mit ein, wenn und soweit Sie tatsächlich
        angefallen ist.
      </div>
    </div>
  );
}

export default ProcessContentLaw;
