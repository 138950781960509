import React from "react";
import Footer from "../../../components/footer/Footer";
import HorizontalPictureTextBox from "../../../components/services/horizontalPictureTextBox/HorizontalPictureTextBox";
import imgEv from "../../../media/services/beweis.png";
import imgProbeAction from "../../../media/services/drohneaktion.png";
import imgCamera from "../../../media/services/feuchtesuche.png";
import imgCap from "../../../media/services/kapmessung.png";
import imgLadder from "../../../media/services/leiter.png";
import imgMobileRoof from "../../../media/services/womodach.png";
import imgRoof from "../../../media/services/reisemobiledach.jpg";
import imgDamage from "../../../media/services/reisemobileschaden.jpg";
import "./TravelMobile.css";

function TravelMobile() {
  return (
    <div>
      <div className="as-container">
        <div className="as-content">
          <div className="as-content-heading">Reisemobile und Wohnanhänger</div>

          <div>
            Campingfahrzeuge erfordern zur Begutachtung spezielle Kenntnisse,
            die nicht jeder Gutachter hat. Diese Fahrzeuge mit den
            unterschiedlichsten Aufbauten und Austattungen sind nur durch
            geprüfte Schulungen und Erfahrungen, sowie mit entsprechendem
            technischen Know-how einzuschätzen und zu begutachten.
          </div>
          <div className="as-container">
            <div className="as-content">
              <div className="as-content-heading">
                Haftpflichtschaden am Reisemobil oder Wohnanhänger
              </div>
              <div className="as-picture-box-container">
                <HorizontalPictureTextBox
                  heading="Schadenserfassung und Feststellung der Schadenshöhe"
                  imgName={imgDamage}
                  paragraphs={[
                    "Auf den ersten Blick ein kleiner Streifschaden",
                    "Nach der Schadenskalkulation Feststellung der Schadenshöhe von 6500,00€",
                  ]}
                ></HorizontalPictureTextBox>
                <HorizontalPictureTextBox
                  heading="Erfassung der Ausstattung"
                  imgName={imgRoof}
                  paragraphs={[
                    "Zur Feststellgung des Wiederbeschaffungswertes ist es gerade bei Reisemobilen zwingend erforderlich die genaue Austattung zu dokumentieren und erfassen.",
                  ]}
                ></HorizontalPictureTextBox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="as-container-blue">
        <div className="as-content as-blue">
          <div className="as-container">
            <div className="as-content">
              <div className="as-blue-heading">
                DROHNENNUTZUNG ZUR BEGUTACHTUNG
              </div>
              <div className="as-picture-box-container">
                <HorizontalPictureTextBox
                  heading="Drohne im Einsatz"
                  imgName={imgProbeAction}
                  paragraphs={[
                    "Schneller unkomplizierter Einsatz.",
                    "Hier kommt die Drohne DJI Mavic Air 2 zum Einsatz. Videos in 4K Qualität und Fotos in 4MP Auflösung können so verarbeitet werden.",
                  ]}
                ></HorizontalPictureTextBox>
                <HorizontalPictureTextBox
                  heading="Fotos aus allen Perspektiven"
                  imgName={imgMobileRoof}
                  paragraphs={[
                    "Fotos nutzbar um Schäden am Dach und Aufbauten zu erkennen. Dokumentation der auf dem Dach befindlichen Aufbauten.",
                    "Hier zum Beispiel vom Wohnmobildach.",
                  ]}
                ></HorizontalPictureTextBox>
                <HorizontalPictureTextBox
                  heading="Klassischer Einsatz der Leiter"
                  imgName={imgLadder}
                  paragraphs={[
                    "Leiter oder Arbeitsbühne sonst erforderlich.",
                    "Kontaktlose Begutachtung des Wohnmobil oder Wohnwagendaches ohne Drohneneinsatz nicht möglich.",
                  ]}
                ></HorizontalPictureTextBox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="as-container">
        <div className="as-content">
          <div className="as-content">
            <div className="as-content-heading">
              Feuchtesuche in Campingfahrzeugen
            </div>
            <div className="as-picture-box-container">
              <HorizontalPictureTextBox
                heading="Feuchtelokalisierung mit Wärmebildkamera"
                imgName={imgCamera}
                paragraphs={[
                  "Feuchtigkeitsmessgerät FLIR MR 160",
                  "Darstellung der Feuchte auf dem Display der Wärmebildkamera (hier mit einem Stück feuchtem Holz). Deutlich die feuchte Stelle erkennbar.",
                ]}
              ></HorizontalPictureTextBox>
              <HorizontalPictureTextBox
                heading="Kapazitive Messung und Messung
            nach dem Leitfähigkeitprinzip möglich"
                imgName={imgCap}
                paragraphs={[
                  "Zerstörungsfreie Messung (kapazitives Messverfahren)",
                  "Hier bei einer Messung am Fenster dargestellt.",
                ]}
              ></HorizontalPictureTextBox>
              <HorizontalPictureTextBox
                heading="Beweissicherung"
                imgName={imgEv}
                paragraphs={[
                  "Die Bilder der Wärmebildkamera lassen sich auf den PC übertragen und dienen der Beweissicherung.",
                  "Messpunkt per Laser fixiert.",
                ]}
              ></HorizontalPictureTextBox>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default TravelMobile;
