import React from "react";
import "./HorizontalPictureTextBox.css";

function HorizontalPictureTextBox({ imgName, heading, paragraphs }) {
  return (
    <div className="box-container">
      <img className="box-image" src={imgName} alt="Missing"></img>
      <div className="box-text">
        <div className="box-text-heading">{heading}</div>
        {paragraphs.map((para) => {
          return <div className="box-text-para" key={para}>{para}</div>;
        })}
      </div>
    </div>
  );
}

export default HorizontalPictureTextBox;
