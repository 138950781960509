import React from "react";
import "./AboutMeSponsoring.css";
import imgSponsoring from "../../../media/aboutme/sponsoring.png";
function AboutMeSponsoring() {
  return (
    <div className="about-me-sponsoring-container">
      <div className="about-me-sponsoring-heading">
        <h3>Sponsoring</h3>
      </div>
      <div className="about-me-sponsoring-content-container">
        <div className="about-me-sponsoring-content-image">
          <img width="80%" src={imgSponsoring} alt="Missing"></img>
        </div>
        <div className="about-me-sponsoring-content-text">
          <h4>
            Mathias-von Neuenburg Werkrealschule, Neuenburg am Rhein /
            CHILDeMOTION e.V.- "Kinderschwimmtag"
          </h4>
          <br />
          Das Kfz-Sachverständigenbüro A. Kuster unterstützt den von
          CHILDeMOTION veranstalteten Kinderschwimmtag an der Mathias-von
          Neuenburg Werkrealschule.
          <br />
          <br />
          Dafür führt der Verein CHILDeMOTION Kinderschwimmtage in Schulen
          durch. *Professionelle Schwimmtrainer* unterrichten in kleinen Gruppen
          die Kinder und nehmen Abzeichen ab. Zudem erhalten die Kinder
          kostenfrei Schwimmbrillen, Schwimmgürtel, Poolnudeln und weiteres
          kostenfreies Schwimmmaterial.
          <br />
          <br />
          So will der Verein garantieren, dass den Kindern auch nach dem Tag
          weiterhin die Begeisterung und Freude am Schwimmen erhalten bleibt.
          Jedem Kind wird außerdem die Möglichkeit gegeben am Schwimmtag ein
          Abzeichen abzulegen.
        </div>
      </div>
    </div>
  );
}

export default AboutMeSponsoring;
