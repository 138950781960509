import React from 'react'
import "./Impressum.css"
import Footer from "../../components/footer/Footer"
import ImpressumContainer from '../../components/impressum/ImpressumContainer'

function Impressum() {
    return (
        <div>
            <ImpressumContainer/>
            <Footer/>
        </div>
    )
}

export default Impressum
