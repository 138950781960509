import React, { useState, useEffect } from 'react'
import "./Introduction.css"
function Introduction () { 
        const [fadeIn, setFadeIn] = useState(false);   
        useEffect (()=>{
                setFadeIn(true)
        },[fadeIn]);
        return (
            <div className="introduction-container">
                    <div className={fadeIn? "text-container text-container-fade-in": "text-container"}>KFZ - Sachverständigenbüro Andreas Kuster</div>
             </div>
        )
}
export default Introduction
