import React from "react";
import IntroductionCard from "../introductionCard/IntroductionCard";
import "./IntroductionCardBox.css";
import imgDutyAdvise from "../../../media/introduction/haftpflicht-gutachten.png";
import imgTravelMobile from "../../../media/introduction/reisemobile-gutachten.png";
import imgAddServices from "../../../media/introduction/weitere-leistungen.png";

function IntroductionCardBox() {
  return (
    <div className="introduction-header">
      
      <div className="introduction-card-container">
        <IntroductionCard
        link="/Services/DutyAdvise"
          imageURI={imgDutyAdvise}
          textHeading="Arten von Gutachten"
          text="z.B. nach einem unverschuldeten Unfall"
        />
        <IntroductionCard
        link="/Services/TravelMobile"
          imageURI={imgTravelMobile}
          textHeading="Reisemobile und Wohnanhänger"
          text="z.B. Ermittlung von Schäden und Reparaturkosten"
        />
        <IntroductionCard
        link="/Services/AdditionalServices"
          imageURI={imgAddServices}
          textHeading="Weitere Leistungen"
          text="z.B. Wärmebildmessung oder Einsatz einer Drohne"
        />
      </div>
    </div>
  );
}

export default IntroductionCardBox;
